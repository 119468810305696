<template>
    <common-page
        :label="ts( 'dev' )" :caption="ts( 'futurePlan' )" :cancel-btn="ts( 'dumpStores' )" :ok-btn="ts( 'clearMemory' )"
        @cancel="dumpStores()"
        @ok="clearStores()">
    </common-page>
</template>

<script setup lang="ts">
import CommonPage from "@parts/commonPage.vue"
import SettingsRepo from "@repo/SettingsRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useDumpStore } from "@/utils/dumpStores"
import { useRepo } from "pinia-orm"

const dumpStore = useDumpStore()

function dumpStores () {
    console.log( dumpStore.all() )
}

function clearStores () {
    useRepo( SettingsRepo ).dbFlushAll()
}
</script>
