<template>
    <q-item clickable v-ripple class="q-pt-sm q-pb-sm q-pl-md q-pr-md" active-class="bg-white text-grey-9 font-bolder">
        <q-item-section avatar>
            <q-icon :name="props.icon" />
        </q-item-section>
        <q-item-section>
            <span>{{ props.label }}</span>
        </q-item-section>
        <q-item-section avatar v-if="props.modified">
            <q-avatar color="orange" text-color="white" icon="edit" size="1.3em" />
        </q-item-section>
    </q-item>
</template>

<script setup lang="ts">
interface Props {
    icon: string,
    label: string,
    modified?: boolean
}

const props = withDefaults( defineProps<Props>(), {
    modified: false
} )
</script>

<style scoped lang="sass">
.font-bolder
  font-weight: bold
</style>
