import EventRace from "@model/EventRace"
import EventRaceSkippedClass from "@model/EventRaceSkippedClass"
import EventResult from "@model/EventResult"
import Setting, { CurrentDbStructureVersion, Defaults, Language, Options } from "@model/Setting"
import { localization } from "@/plugins/i18n"
import { $ResetPinia } from "@/utils/reset-stores"
import CtlClass from "@model/CtlClass"
import EventBoat from "@model/EventBoat"
import EventClass from "@model/EventClass"
import EventCrew from "@model/EventCrew"
import EventLifeguard from "@model/EventLifeguard"
import EventReferee from "@model/EventReferee"
import BoatClassesRepo from "@repo/BoatClassesRepo"
import ClubsRepo from "@repo/ClubsRepo"
import CountriesRepo from "@repo/CountriesRepo"
import CtlRepo from "@repo/CtlRepo"
import EventCoefficientsRepo from "@repo/EventCoefficientsRepo"
import EventsRepo from "@repo/EventsRepo"
import LifeguardRolesRepo from "@repo/LifeguardRolesRepo"
import LifeguardsRepo from "@repo/LifeguardsRepo"
import MembersRepo from "@repo/MembersRepo"
import RefereeRolesRepo from "@repo/RefereeRolesRepo"
import RefereesRepo from "@repo/RefereesRepo"
import RefereeTitlesRepo from "@repo/RefereeTitlesRepo"
import VenuesRepo from "@repo/VenuesRepo"
import { Repository, useRepo } from 'pinia-orm'

export default class SettingsRepo extends Repository {
    use = Setting

    getOption ( option: string, defaultValue = null ) {
        const value = this.where( 'option', option ).first()?.value

        return value == null ? defaultValue : ( value.isNumeric() ? parseFloat( value ) : value )
    }

    setOption ( option: string, value: unknown ) {
        this.destroy( this.where( 'option', option ).get().map( ( setting ) => ( setting.id ) ) )

        if ( value !== null )
            this.save( { option: option, value: String( value ) } )

        this.triggerActions( option )
    }

    triggerActions ( option ) {
        if ( option === Options.Language ) {
            localization.global.locale.value = ( this.getOption( Options.Language, Defaults.Language ) == Language.Czech ? 'cs' : 'en' )
            return
        }

        if ( option === Options.DbStructureVersion ) {
            const versionNow = this.getOption( Options.DbStructureVersion, Defaults.DbStructureVersion )

            if ( versionNow === null || parseInt( versionNow ) < CurrentDbStructureVersion )
                this.upgradeDbVersion( versionNow )
        }
    }

    upgradeDbVersion ( currentVersion ) {
        if ( currentVersion === null )
            this.dbFlushAll()

        this.dbFlushCatalogs()

        this.setOption( Options.DbStructureVersion, CurrentDbStructureVersion )
    }

    dbFlushAll () {
        useRepo( EventResult ).flush()
        useRepo( EventRaceSkippedClass ).flush()
        useRepo( EventRace ).flush()
        useRepo( EventCrew ).flush()
        useRepo( EventBoat ).flush()
        useRepo( EventClass ).flush()
        useRepo( EventReferee ).flush()
        useRepo( EventLifeguard ).flush()
        useRepo( EventsRepo ).flush()

        this.dbFlushCatalogs()

        useRepo( SettingsRepo ).flush()

        $ResetPinia().all()
    }

    dbFlushCatalogs () {
        useRepo( CtlClass ).flush()
        useRepo( CtlRepo ).flush()
        useRepo( MembersRepo ).flush()
        useRepo( RefereesRepo ).flush()
        useRepo( LifeguardsRepo ).flush()
        useRepo( EventCoefficientsRepo ).flush()
        useRepo( RefereeTitlesRepo ).flush()
        useRepo( RefereeRolesRepo ).flush()
        useRepo( LifeguardRolesRepo ).flush()
        useRepo( VenuesRepo ).flush()
        useRepo( BoatClassesRepo ).flush()
        useRepo( ClubsRepo ).flush()
        useRepo( CountriesRepo ).flush()

        useRepo( SettingsRepo ).setOption( Options.SailingDataLoadingState, 'false' )
    }
}