import EventBoat from '@model/EventBoat'
import EventClass from '@model/EventClass'
import EventCrew from '@model/EventCrew'
import EventLifeguard from '@model/EventLifeguard'
import EventRace from '@model/EventRace'
import EventRaceSkippedClass from '@model/EventRaceSkippedClass'
import EventReferee from '@model/EventReferee'
import EventResult from '@model/EventResult'
import EventsRepo from '@repo/EventsRepo'
import { useRepo } from 'pinia-orm'

export function createEventFromFile ( content ) {
    const storedEvent = useRepo( EventsRepo ).createEvent( {
        title: content.event.title,
        ctlId: parseInt( content.event.ctl ),
        from: content.event.from.toDate(),
        to: content.event.to === null ? null : content.event.to.toDate(),
        venueId: content.event.venueId,
        clubId: content.event.clubId,
        organizer: content.event.organizer,
        otherVenue: content.event.otherVenue,
        registrationUrl: content.event.registrationUrl,
        noticeboardUrl: content.event.noticeboardUrl,
        description: content.event.description,
        sponsor: content.event.sponsor,
        director: content.event.director,
        plannedRaces: content.event.plannedRaces,
        created: content.event.created.toDate(),
        noLifeguards: content.event.noLifeguards,
        ignoreUpdateMembers: content.event.ignoreUpdateMembers,
    } )

    useRepo( EventsRepo ).save( storedEvent )

    content.event.classes.forEach( function ( eventClass ) {
        useRepo( EventClass ).save( {
            eventId: storedEvent.id,
            boatClassId: eventClass.boatClassId,
            weight: eventClass.weight,
            eventType: eventClass.eventType,
            shortcut: eventClass.shortcut,
            discardDefinition: eventClass.discardDefinition,
        } )
    } )

    content.event.referees.forEach( function ( referee ) {
        useRepo( EventReferee ).save( {
            eventId: storedEvent.id,
            refereeId: referee.refereeId,
            refereeRoleId: referee.refereeRoleId,
        } )
    } )

    content.event.lifeguards.forEach( function ( lifeguard ) {
        useRepo( EventLifeguard ).save( {
            eventId: storedEvent.id,
            lifeguardId: lifeguard.lifeguardId,
            lifeguardRoleId: lifeguard.lifeguardRoleId,
        } )
    } )

    const boats = new Map<string, string>

    content.event.boats.forEach( function ( boat ) {
        const storedBoat = useRepo( EventBoat ).save( {
            eventId: storedEvent.id,
            boatClassId: boat.boatClassId,
            country: boat.country,
            sailNumber: boat.sailNumber,
            name: boat.name,
            detail: boat.detail,
        } )

        boats.set( boat.id, storedBoat.id )

        boat.crew.forEach( function ( crew ) {
            useRepo( EventCrew ).save( {
                eventBoatId: storedBoat.id,
                memberId: crew.memberId,
                captain: crew.captain,
                order: crew.order,
                nonMemberRegId: crew.nonMemberRegId,
                nonMemberFirstName: crew.nonMemberFirstName,
                nonMemberLastName: crew.nonMemberLastName,
                nonMemberSex: crew.nonMemberSex,
                nonMemberBirthDate: crew.nonMemberBirthDate === null ? null : crew.nonMemberBirthDate.toDate(),
                nonMemberBirthCode: crew.nonMemberBirthCode,
                nonMemberCitizenship: crew.nonMemberCitizenship,
                nonMemberAddress: crew.nonMemberAddress,
                nonMemberEmail: crew.nonMemberEmail,
                nonMemberPhone: crew.nonMemberPhone,
            } )
        } )
    } )

    content.event.races.forEach( function ( race ) {
        const eventRace = useRepo( EventRace ).save( {
            eventId: storedEvent.id,
            race: race.race,
        } )

        race.skippedClasses.forEach( function ( skipClass ) {
            useRepo( EventRaceSkippedClass ).save( {
                eventRaceId: eventRace.id,
                boatClassId: skipClass.boatClassId,
            } )
        } )

        race.results.forEach( function ( result ) {
            useRepo( EventResult ).save( {
                eventBoatId: boats.get( result.eventBoatId ),
                eventRaceId: eventRace.id,
                order: result.order,
                other: result.other,
                attr: result.attr,
            } )
        } )
    } )

    return storedEvent.id
}