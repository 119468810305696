import Lifeguard from "@/orm/models/Lifeguard"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { AxiosRepository } from "@pinia-orm/axios"
import { useRepo } from "pinia-orm"

export default class LifeguardsRepo extends AxiosRepository<Lifeguard> {
    use = Lifeguard

    hasReferees () {
        return this.all().length > 0
    }

    orderedList ( event = null ) {
        let list = this.all()

        if ( event != null ) {
            const lifeguards = event.lifeguards.map( ( eventLifeguard ) => ( eventLifeguard.lifeguard.id ) )

            list = list.filter( ( lifeguard ) => ( lifeguards.indexOf( lifeguard.id ) == -1 ) )
        }

        return list.map( ( lifeguard ) => ( {
                label: lifeguard.reverseFullNameWithIdentification,
                sortLabel: lifeguard.reverseFullName + ' ' + lifeguard.id,
                searchLabel: lifeguard.reverseFullName + ' ' + lifeguard.fullName + ' L' + lifeguard.id,
                value: lifeguard.id
            } ) )
            .sort( ( a, b ) => a.sortLabel.localeCompare( b.sortLabel ) )
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        if ( authToken.length === 0 ) {
            this.flush()

            return Promise.allSettled( [] )
        }

        this.flush()
        return this.api().get( '/catalogs/lifeguards', {
            headers: { Authorization: "Bearer " + authToken },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: String( line.id ),
                    firstName: String( line.firstName ),
                    lastName: String( line.lastName ),
                    regId: String( line.memberId ).substring( 0, 4 ) + "-" + String( line.memberId ).substring( 4 ),
                    email: Object.hasOwn( line, 'email' ) ? String( line.email ) : undefined,
                    phone: Object.hasOwn( line, 'phone' ) ? String( line.phone ) : undefined
                } ) )
            }
        } )
    }
}
