import Country from "@model/Country"
import EventBoat from "@model/EventBoat"
import Member from "@model/Member"
import { DateFormat, ds, ts } from '@/plugins/i18n-formatted'
import nvl from "@/utils/nvl"
import ClubsRepo from "@repo/ClubsRepo"
import { Model, useRepo } from "pinia-orm"
import { DateCast } from "pinia-orm/casts"
import { BelongsTo, Uid, Str, Num, Bool, Cast, Attr } from "pinia-orm/decorators"

export function crewDump ( crew ) {
    return {
        memberId: crew.memberId,
        captain: crew.captain,
        order: crew.order,
        nonMemberRegId: crew.nonMemberRegId,
        nonMemberFirstName: crew.nonMemberFirstName,
        nonMemberLastName: crew.nonMemberLastName,
        nonMemberSex: crew.nonMemberSex,
        nonMemberBirthDate: crew.nonMemberBirthDate === null ? crew.nonMemberBirthDate : ds( crew.nonMemberBirthDate, DateFormat.SystemDate ),
        nonMemberBirthCode: crew.nonMemberBirthCode,
        nonMemberCitizenship: crew.nonMemberCitizenship,
        nonMemberAddress: crew.nonMemberAddress,
        nonMemberEmail: crew.nonMemberEmail,
        nonMemberPhone: crew.nonMemberPhone
    }
}

export function crewHash( crewDump ) {
    return JSON.stringify( Object.keys( crewDump ).filter( ( key ) => crewDump[ key ] !== null ).sort().reduce( ( acc, key ) => ( { ...acc, [key]: crewDump[ key ] } ), {} ) )
}

export default class EventCrew extends Model {
    static entity = 'events-crew'

    @Uid() declare id: string
    @Str( '', { notNullable: true } ) declare eventBoatId: string
    @BelongsTo( () => EventBoat, 'eventBoatId' ) declare eventBoat: EventBoat
    @Str( null ) declare memberId: string | null
    @BelongsTo( () => Member, 'memberId' ) declare member: Member
    @Str( null ) declare nonMemberRegId: string | null
    @Str( null ) declare nonMemberFirstName: string | null
    @Str( null ) declare nonMemberLastName: string | null
    @Str( null ) declare nonMemberSex: string | null
    @Cast( () => DateCast ) @Attr( null ) declare nonMemberBirthDate: string | null
    @Str( null ) declare nonMemberBirthCode: string | null
    @Bool( false, { notNullable: true } ) declare captain: boolean
    @Num( 0, { notNullable: true } ) declare order: number
    @Num( null ) declare nonMemberCitizenship: number | null
    @BelongsTo( () => Country, 'nonMemberCitizenship' ) declare nonMemberCitizenshipCountry: Country
    @Str( null ) declare nonMemberAddress: string | null
    @Str( null ) declare nonMemberEmail: string | null
    @Str( null ) declare nonMemberPhone: string | null

    get dump () {
        return crewDump( this )
    }

    get canImproveToPublic (): boolean {
        const publicMode = this.eventBoat.eventClassSimple?.publicMode ?? 0

        return this.isOneTime && publicMode > 0 && ( !this.captain || publicMode === 2 )
    }

    get canBeCaptain (): boolean {
        return !this.captain && ( !this.isPublic || this.eventBoat.eventClassSimple?.publicMode === 2 )
    }

    get isValid (): boolean {
        return this.eventBoatId !== '' && ( this.memberId !== '' || this.isValidForeign || this.isValidOneTime || this.isValidPublic )
    }

    get isPublic (): boolean {
        return this.nonMemberRegId !== null && this.nonMemberRegId.substring( 0, 4 ) === '8888'
    }

    get isOneTime (): boolean {
        return this.nonMemberRegId !== null && this.nonMemberRegId.substring( 0, 4 ) === '9999'
    }

    get isForeign (): boolean {
        return this.nonMemberRegId !== null && ( this.nonMemberRegId.substring( 0, 4 ) !== '9999' && parseInt( this.nonMemberRegId.substring( 0, 4 ) ) > 9000 )
    }

    get isValidForeign (): boolean {
        return this.isForeign && this.isValidNonMember
    }

    get isValidNonMember (): boolean {
        return this.nonMemberRegId !== null && this.nonMemberRegId.length === 8 && this.nonMemberFirstName !== null && this.nonMemberFirstName !== '' && this.nonMemberLastName !== null && this.nonMemberLastName !== '' && ( this.nonMemberSex === 'male' || this.nonMemberSex === 'female' )
    }

    get isValidOneTime (): boolean {
        return this.isOneTime && this.isValidNonMember
    }

    get isValidPublic (): boolean {
        return this.isPublic && this.isValidNonMember
    }

    get regId () {
        return ( this.memberId !== null && this.member !== null ) ? this.member?.regId : this.nonMemberRegId
    }

    get firstName () {
        return ( this.memberId !== null && this.member !== null ) ? this.member?.firstName : this.nonMemberFirstName
    }

    get lastName () {
        return ( this.memberId !== null && this.member !== null ) ? this.member?.lastName : this.nonMemberLastName
    }

    get isLicenseIssue () {
        return this.memberId !== null && ( this.member === null || ! this.member.isLicensed )
    }

    get fullName () {
        return this.firstName + " " + this.lastName
    }

    get reverseFullName () {
        return this.lastName + " " + this.firstName
    }

    get sex () {
        return ( this.memberId !== null && this.member !== null ) ? this.member?.sex : this.nonMemberSex
    }

    get email () {
        return ( this.memberId !== null && this.member !== null ) ? this.member?.email : this.nonMemberEmail
    }

    get phone () {
        return ( this.memberId !== null && this.member !== null ) ? this.member?.phone : this.nonMemberPhone
    }

    get birthYear () {
        return this.birthDate?.getFullYear()
    }

    get birthDate () {
        if ( this.memberId !== null && this.member !== null ) {
            return this.member?.birthDate !== null ? new Date( this.member?.birthDate ) : null
        }

        return this.nonMemberBirthDate !== null ? new Date( this.nonMemberBirthDate ) : null
    }

    get visibleClub () {
        if ( this.memberId !== null )
            return nvl( this.member?.club?.shortcut, this.member?.club?.name )

        if ( this.nonMemberRegId === null )
            return ''

        if ( this.isOneTime )
            return ts( 'oneTimeLicense' )

        if ( this.isPublic )
            return ts( 'publicLicense' )

        if ( this.isForeign )
            return useRepo( ClubsRepo ).find( parseInt( this.nonMemberRegId.substring( 0, 4 ) ) )?.name

        return ''
    }

    static piniaOptions = {
        persist: true
    }
}
