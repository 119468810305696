import BoatClass from "@/orm/models/BoatClass"
import Event from "@/orm/models/Event"
import Sandbox from "@nyariv/sandboxjs"
import EventsRepo from "@repo/EventsRepo"
import { Model, useRepo } from 'pinia-orm'
import { BelongsTo, Num, Str, Uid } from 'pinia-orm/decorators'
import EventCoefficientsRepo from "@/orm/repositories/EventCoefficientsRepo"

export default class EventClass extends Model {
    static entity = 'events-classes'

    @Uid() declare id: string
    @Uid() declare eventId: string
    @BelongsTo( () => Event, 'eventId' ) declare event: Event
    @Num( 0, { notNullable: true } ) declare boatClassId: number
    @BelongsTo( () => BoatClass, 'boatClassId' ) declare boatClass: BoatClass
    @Num( 0, { notNullable: true } ) declare weight: number
    @Str( null ) declare eventType: string | null
    @Str( '', { notNullable: true } ) declare shortcut: string
    @Str( 'R4, R8' ) declare discardDefinition: string

    get dump () {
        return {
            boatClassId: this.boatClassId,
            weight: this.weight,
            eventType: this.eventType,
            shortcut: this.shortcut,
            discardDefinition: this.discardDefinition
        }
    }

    get publicMode () {
        const event = ( this.event === undefined || this.event === null ) ? useRepo( EventsRepo ).find( this.eventId ) : this.event

        if ( event.ctlId === 251603 || event.ctlId === 251308 || event.ctlId === 251317 || event.ctlId === 251320 )
            return 2

        if ( this.weight === 1 && ( this.boatClass.shortcut === 'Kaj' || this.boatClass.shortcut.toUpperCase() === 'NJ' ) )
            return 1

        return 0
    }

    get eventTypeDesc () {
        if ( this.eventType != null && this.eventType != '' ) {
            const coef = useRepo( EventCoefficientsRepo ).where( 'value', this.weight ).where( 'shortcut', this.eventType ).first()

            if ( coef !== null )
                return coef.name
        }

        const coef = useRepo( EventCoefficientsRepo ).where( 'value', this.weight ).first()

        if ( coef !== null )
            return coef.name

        return String( this.weight ) + ( this.eventType != null ? this.eventType : '' )
    }

    get eventTypeNormalized () {
        return String( this.weight ) + ( this.eventType != null ? this.eventType : '' )
    }

    set eventTypeNormalized ( val ) {
        const weight = val.replaceAll( /\D+/g, '' )
        const eventTypeChar = val.replace( /^\d+/, '' )

        this.weight = parseInt( weight )
        this.eventType = ( eventTypeChar == '' ? null : eventTypeChar )
        this.shortcut = this.boatClass.shortcut + '-' + String( weight ) + ( eventTypeChar != null ? eventTypeChar : '' )

        useRepo( EventClass ).save( this )
    }

    get boats () {
        let event = this.event

        if ( event === undefined || event === null || event.boats === undefined )
            event = useRepo( Event ).withAllRecursive().find( this.eventId )

        return event.boats.filter( boat => boat.boatClassId === this.boatClassId )
    }

    get areAllBoatsValid () {
        const boats = this.boats

        return boats.length > 0 && boats.filter( boat => !boat.isValid ).length === 0
    }

    get totalBoats () {
        return this.boats.length
    }

    get totalRacedBoats () {
        return this.boats.filter( boat => boat.raced ).length
    }

    get raced () {
        return this.event.availableRaces( this.boatClassId ).length
    }

    get calculatedWeight () {
        const raced = this.raced
        const boats = this.totalRacedBoats

        if ( this.weight >= 8 && boats >= 10 && raced >= 3 )
            return this.weight

        if ( this.weight >= 3 && boats >= 5 && raced >= 3 )
            return this.weight >= 8 ? 7 : this.weight

        if ( this.weight >= 2 && boats >= 5 && raced >= 2 )
            return 2

        if ( this.weight >= 1 && boats >= 3 && raced >= 1 )
            return 1

        return 0
    }

    get discarded () {
        /**
         * Discard definition can use different formulas:
         * 0,0,0,1,1,1,1,2 (as of SailWave, more races have same as highest number of discarded races)
         * R4,R8 (at what race number is number of discarded races increment)
         * magical formula with "s" (number of sailed races and how to calculare number of discarded races, truncated to floor), e.g. "0.8 * ( s - 1 )"
         * ditto with "r" for number of planned races
         */

        const definition = this.discardDefinition.trim()

        if ( definition.indexOf( ',' ) >= 0 ) {
            let partDefinitions = definition.split( ',' ).map( item => item.trim().toUpperCase() )

            if ( partDefinitions[ 0 ].charAt( 0 ) === 'R' ) {
                let generated = 0
                let current = 0

                const result = partDefinitions.sort( ( a, b ) => parseInt( a.substring( 1 ) ) - parseInt( b.substring( 1 ) ) )
                    .reduce<number[]>( ( accumulator, def ) => {
                        const race = def.substring( 1 )
                        if ( !isNaN( +race ) ) {
                            const newOne = Array( +race - generated - 1 ).fill( current )

                            generated += newOne.length
                            ++current

                            return [ ...accumulator, ...newOne ]
                        }

                        return accumulator
                    }, [] )

                if ( current <= partDefinitions.length )
                    result.push( current )

                partDefinitions = result.map( item => String( item ) )
            }

            if ( partDefinitions.length < this.raced )
                return partDefinitions[ partDefinitions.length - 1 ]

            return partDefinitions[ this.raced - 1 ]
        }

        if ( definition.charAt( 0 ) === 'R' ) {
            const race = definition.substring( 1 )

            if ( !isNaN( +race ) )
                return ( this.raced < +race ) ? 0 : 1
        }

        const sandbox = new Sandbox()
        const exec = sandbox.compile( 'return ' + definition )

        const result = exec( {
            r: ( this.event.plannedRaces !== undefined && this.event.plannedRaces !== null ) ? this.event.plannedRaces : undefined,
            s: this.raced
        } ).run()

        if ( !isNaN( result ) )
            return result

        return 0
    }

    get maxCrew () {
        return Math.max( 0, ...this.boats.map( b => b.crew.length ) )
    }

    get isBoatNamesUsed () {
        return this.boats.some( b => b.name !== null && b.name !== undefined && b.name?.trim() !== '' )
    }

    static piniaOptions = {
        persist: true
    }
}
