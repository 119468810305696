<template>
    <common-page :label="ts( 'selectEvent' )" :use-buttons="false">
        <q-select
            v-model="venueId" autofocus :options="venuesFilteredList" :label="ts( 'venue' )" option-value="value"
            option-label="label" use-input clearable emit-value map-options input-debounce="300"
            @filter="venueFilter"
            @keydown.enter="chooseOnlyOneVenue()"
            @update:model-value="venueId => settingsRepo.setOption( Options.PreferredVenue, venueId )" />

        <q-input
            ref="eventFromField" v-model="eventFrom" type="date" :label="ts( 'eventFrom' )"
            @keydown.enter="eventToField.focus()"
            @update:model-value="from => settingsRepo.setOption( Options.SelectEventFrom, from )" />

        <q-input
            ref="eventToField" v-model="eventTo" type="date" :label="ts( 'eventTo' )"
            @update:model-value="to => settingsRepo.setOption( Options.SelectEventTo, to )" />

        <q-card-actions align="right">
            <q-btn
                outline color="secondary" icon="all_inclusive" class="q-mt-md"
                :title="ts( 'wholeYearHint' )"
                @click="setWholeYear()" />
            <q-btn
                outline color="secondary" icon="pending_actions" class="q-mt-md"
                :title="ts( 'currentDateEventHint' )"
                @click="setCurrentDates()" />
        </q-card-actions>

        <q-separator class="q-mt-lg q-mb-lg" />

        <q-table flat :rows="eventsData" :columns="eventColumns" row-key="id" binary-state-sort :no-data-label="ts( 'noEventsFound' )" :rows-per-page-options="[10,20,30,40,50,0]">
            <template v-slot:body="props">
                <q-tr :props="props" @click="selectEvent( props.row.id )" class="cursor-pointer">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <qe-btn
                            color="primary" :label="ts( 'create' )" v-if="col.name === 'actions'"
                            @click.stop="selectEvent( props.row.id )" />
                        {{ col.value }}
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </common-page>
</template>

<script setup lang="ts">
// Purpose: select event from list

import Confirmation from "@/components/dialogs/Confirmation.vue"
import CommonPage from "@/components/parts/commonPage.vue"
import QeBtn from "@/components/qe/qeBtn.vue"
import EventClass from "@/orm/models/EventClass"
import { Defaults, Options } from "@/orm/models/Setting"
import CtlRepo from "@/orm/repositories/CtlRepo"
import EventsRepo from "@/orm/repositories/EventsRepo"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import VenuesRepo from "@/orm/repositories/VenuesRepo"
import { ts } from '@/plugins/i18n-formatted'
import nvl from '@/utils/nvl'
import { useRepo } from "pinia-orm"
import { useQuasar } from "quasar"
import { computed, onMounted, ref } from 'vue'
import { useRouter } from "vue-router"

// Event selector dates
let origEventFrom = new Date()
origEventFrom.setDate( origEventFrom.getDate() - 14 )

let origEventTo = new Date()
origEventTo.setMonth( origEventTo.getMonth() + 1 )

let yearStart = new Date()
yearStart.setDate( 1 )
yearStart.setMonth( 0 )

let yearEnd = new Date()
yearEnd.setDate( 31 )
yearEnd.setMonth( 11 )

// Repositories
const venuesRepo = computed( () => ( useRepo( VenuesRepo ) ) )
const eventsRepo = computed( () => ( useRepo( CtlRepo ) ) )
const settingsRepo = computed( () => ( useRepo( SettingsRepo ) ) )

// Lists
const venuesFilteredList = ref( venuesRepo.value.venuesList() )

// Data layer
const eventFromField = ref()
const eventToField = ref()

const venueId = ref( settingsRepo.value.getOption( Options.PreferredVenue, Defaults.PreferredVenue ) )
const eventFrom = ref( nvl( settingsRepo.value.getOption( Options.SelectEventFrom, Defaults.SelectEventFrom ), origEventFrom.toSimpleDate() ) )
const eventTo = ref( nvl( settingsRepo.value.getOption( Options.SelectEventTo, Defaults.SelectEventTo ), origEventTo.toSimpleDate() ) )

const router = useRouter()
const quasar = useQuasar()

const emits = defineEmits( [ 'syncData' ] )

const eventsData = computed( () => {
    let filteredList = useRepo( CtlRepo ).withAll().get()

    if ( venueId.value != null )
        filteredList = filteredList.filter( event => event.venueId != null && event.venueId == venueId.value )

    if ( eventFrom.value != null ) {
        const evFrom = new Date( eventFrom.value )

        filteredList = filteredList.filter( ( event ) => event.from >= evFrom )
    }

    if ( eventTo.value != null ) {
        const evTo = new Date( eventTo.value )

        filteredList = filteredList.filter( event => event.to == null || event.to <= evTo )
    }

    return filteredList.sort( ( a, b ) => ( new Date ( a.from ) - new Date( b.from ) ) )
} )

const eventColumns = [
    {
        name: 'ctlId',
        required: true,
        label: ts( 'ctl' ),
        align: 'center',
        field: row => row.id,
        sortable: true
    },
    {
        name: 'title',
        required: true,
        label: ts( 'eventTitle' ),
        align: 'left',
        field: row => row.name,
        sortable: true
    },
    {
        name: 'dateRange',
        required: true,
        label: ts( 'eventTerm' ),
        align: 'right',
        field: row => row.dateRange,
        sortable: true
    },
    {
        name: 'venue',
        required: true,
        label: ts( 'venue' ),
        align: 'left',
        field: row => nvl( row.venue?.name, row.otherVenue ),
        sortable: true
    },
    {
        name: 'actions',
        required: true,
        label: ts( 'actions' ),
        align: 'center',
        field: () => '',
        sortable: false
    }
]

onMounted( () => {
    if ( !eventsRepo.value.hasEvents() ) {
        quasar.dialog( {
                component: Confirmation,
                componentProps: {
                    icon: 'refresh',
                    question: ts( 'wantSync' ),
                    yes: ts( 'update' ),
                    no: ts( 'notNeeded' )
                }
            } )
            .onOk( () => {
                emits( 'syncData' )
            } )
    }
} )

function setCurrentDates () {
    eventFrom.value = origEventFrom.toSimpleDate()
    eventTo.value = origEventTo.toSimpleDate()

    settingsRepo.value.setOption( Options.SelectEventFrom, null )
    settingsRepo.value.setOption( Options.SelectEventTo, null )
}

function setWholeYear () {
    eventFrom.value = yearStart.toSimpleDate()
    eventTo.value = yearEnd.toSimpleDate()

    settingsRepo.value.setOption( Options.SelectEventFrom, eventFrom.value )
    settingsRepo.value.setOption( Options.SelectEventTo, eventTo.value )
}

function venueFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            venuesFilteredList.value = venuesRepo.value.venuesList()
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        venuesFilteredList.value = venuesRepo.value.venuesList().filter( event => event.label.toSearchable().indexOf( needle ) > -1 )
    } )
}

function selectEvent ( eventId, force = false ) {
    if ( eventId == null )
        return

    const event = useRepo( CtlRepo ).where( 'id', eventId ).withAllRecursive().first()

    if ( event == null )
        return

    if ( !force && useRepo( EventsRepo ).eventByCtlId( event.id ) != null ) {
        quasar.dialog( {
                component: Confirmation,
                componentProps: {
                    icon: 'event',
                    question: ts( 'dupEventConfirm', { ctlId: event.id, title: event.name } ),
                    yes: ts( 'createNewOne' ),
                    no: ts( 'openRecent' )
                }
            } )
            .onOk( () => ( selectEvent( eventId, true ) ) )
            .onCancel( () => ( justOpen( eventId ) ) )
    } else {
        const storedEvent = useRepo( EventsRepo ).createEvent( {
            title: event.name,
            ctlId: event.id,
            from: new Date( event.from ),
            venueId: event.venueId,
            modified: true
        } )

        if ( event.to != null )
            storedEvent.to = String( new Date( event.to ) )

        if ( event.clubId != null )
            storedEvent.clubId = event.clubId

        if ( event.organizer != null )
            storedEvent.organizer = event.organizer

        if ( event.otherVenue != null )
            storedEvent.otherVenue = event.otherVenue

        if ( event.registrationUrl != null )
            storedEvent.registrationUrl = event.registrationUrl

        if ( event.noticeboardUrl != null )
            storedEvent.noticeboardUrl = event.noticeboardUrl

        if ( event.description != null )
            storedEvent.description = event.description

        useRepo( EventsRepo ).save( storedEvent )

        let usedClass = []

        if ( event.classes != null )
            for ( let ctlClass of event.classes.sort( ( a, b ) => ( b.weight - a.weight ) ) ) {
                if ( usedClass.indexOf( ctlClass.boatClassId ) == -1 ) {
                    const eventClass = useRepo( EventClass ).make( {
                        eventId: storedEvent.id,
                        boatClassId: ctlClass.boatClassId,
                        weight: ctlClass.weight,
                        shortcut: ctlClass.shortcut
                    } )

                    if ( ctlClass.eventType != null )
                        eventClass.eventType = ctlClass.eventType

                    useRepo( EventClass ).save( eventClass )

                    usedClass.push( ctlClass.boatClassId )
                }
            }

        router.push( { name: 'EventManager', params: { eventId: storedEvent.id } } )
    }
}

function chooseOnlyOneVenue () {
    if ( venuesFilteredList.value != null && venuesFilteredList.value.length == 1 ) {
        venueId.value = venuesFilteredList.value[ 0 ].value
        eventFromField.value.focus()
    }
}

function justOpen ( eventId ) {
    if ( eventId == null )
        return

    const event = useRepo( CtlRepo ).find( eventId )

    if ( event == null )
        return

    const storedEvent = useRepo( EventsRepo ).eventByCtlId( event.id )

    if ( storedEvent != null )
        router.push( { name: 'EventManager', params: { eventId: storedEvent.id } } )
}
</script>
