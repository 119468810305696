import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { AxiosRepository } from "@pinia-orm/axios"
import BoatClass from '@/orm/models/BoatClass'
import { useRepo } from "pinia-orm"

export default class BoatClassesRepo extends AxiosRepository<BoatClass> {
    use = BoatClass

    classesList ( event = null ) {
        let list = this.all()

        if ( event != null ) {
            const eventClasses = event.classes.map( ( eventClass ) => ( eventClass.boatClassId ) )

            list = list.filter( ( boatClass ) => ( eventClasses.indexOf( boatClass.id ) == -1 ) )
        }

        return list.map( ( boatClass ) => ( { label: boatClass.name, shortcut: boatClass.shortcut, code: boatClass.id, value: boatClass.id } ) )
            .sort( ( a, b ) => a.label.localeCompare( b.label ) )
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        this.flush()

        return this.api().get( '/catalogs/classes', {
            headers: { Authorization: authToken.length > 0 ? "Bearer " + authToken : "" },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: parseInt( line.id ),
                    name: String( line.name ),
                    shortcut: String( line.shortcut ),
                    flag: ( Object.hasOwn( line, 'flag' ) && line.flag !== null ) ? String( line.flag ) : undefined,
                    crewSize: Object.hasOwn( line, 'crewSize' ) ? parseInt( line.crewSize ) : undefined,
                    scoringSystem: Object.hasOwn( line, 'scoringSystem' ) ? String( line.scoringSystem ) : undefined,
                    useBoatNames: Object.hasOwn( line, 'useBoatNames' ) ? Boolean( line.useBoatNames ) : false,
                    useHandicaps: Object.hasOwn( line, 'useHandicaps' ) ? Boolean( line.useHandicaps ) : false
                } ) )
            }
        } )
    }
}