import Ctl from "@/orm/models/Ctl"
import CtlClass from "@/orm/models/CtlClass"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import VenuesRepo from "@/orm/repositories/VenuesRepo"
import { AxiosRepository, useAxiosRepo } from "@pinia-orm/axios"
import { useRepo } from 'pinia-orm'

export default class CtlRepo extends AxiosRepository<Ctl> {
    use = Ctl

    hasEvents () {
        return this.all().length > 0
    }

    eventsList () {
        return this.all()
            .map( ( event ) => ( {
                label: event.id + ' ' + event.name,
                value: event.id,
                venue: event.venueId,
                from: new Date( event.from ),
                to: event.to == null ? null : new Date( event.to )
            } ) )
            .sort( ( a, b ) => a.label.localeCompare( b.label ) )
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        this.flush()

        const response1 = this.api().get( '/events/list', {
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => {
                    const eventId = parseInt( line.id )
                    const from = new Date( line.from )
                    const to = Object.hasOwn( line, 'to' ) ? new Date( line.to ) : undefined
                    const venue = Object.hasOwn( line, 'venue' ) ? String( line.venue ) : null
                    const venueId = ( venue != null && venue.length > 0 ) ? useRepo( VenuesRepo ).venueIdByName( venue ) : undefined
                    let eventTo = null

                    if ( typeof to === 'undefined' && Object.hasOwn( line, 'days' ) ) {
                        eventTo = new Date( line.from )
                        eventTo.setDate( from.getDate() + parseInt( line.days ) )
                    }

                    return {
                        cancelled: Object.hasOwn( line, 'cancelled' ) ? Boolean( line.cancelled ) : false,
                        clubId: Object.hasOwn( line, 'clubId' ) ? parseInt( line.clubId ) : undefined,
                        days: Object.hasOwn( line, 'days' ) ? parseInt( line.days ) : ( typeof to !== 'undefined' ? Math.floor( ( to.valueOf() - from.valueOf() ) / ( 1000 * 60 * 60 * 24 ) ) : undefined ),
                        description: Object.hasOwn( line, 'description' ) ? String( line.description ) : undefined,
                        from: from,
                        id: eventId,
                        name: String( line.name ),
                        noticeboardUrl: Object.hasOwn( line, 'noticeboardUrl' ) ? String( line.noticeboardUrl ) : undefined,
                        organizer: Object.hasOwn( line, 'organizer' ) ? String( line.organizer ) : undefined,
                        otherVenue: venueId == null ? venue : undefined,
                        registrationUrl: Object.hasOwn( line, 'registrationUrl' ) ? String( line.registrationUrl ) : undefined,
                        to: typeof to === 'undefined' ? eventTo : to,
                        venueId: venueId != null ? venueId : undefined
                    }
                } )
            },
            headers: { Authorization: authToken.length > 0 ? "Bearer " + authToken : "" }
        } )

        const response2 = useAxiosRepo( CtlClass ).api().get( '/events/list', {
            dataTransformer: ( { data } ) => {
                const res = data.map( ( line ) => {
                    function elementTransformer ( ctlId, element ) {
                        return {
                            id: ctlId + "_" + parseInt( element.classId ) + "_" + parseInt( element.weight ) + "_" + ( Object.hasOwn( element, 'eventType' ) ? String( element.eventType ) : '' ),
                            ctlId: ctlId,
                            boatClassId: parseInt( element.classId ),
                            weight: parseInt( element.weight ),
                            shortcut: String( element.shortcut ),
                            eventType: Object.hasOwn( element, 'eventType' ) ? String( element.eventType ) : ''
                        }
                    }

                    return line.classes.map( elementTransformer.bind( null, parseInt( line.id ) ) )
                } )

                return res.flat()
            },
            headers: { Authorization: authToken.length > 0 ? "Bearer " + authToken : "" }
        } )

        return Promise.allSettled( [ response1, response2 ] )
    }

}