import Club from "@/orm/models/Club"
import { Model } from 'pinia-orm'
import { DateCast } from "pinia-orm/casts"
import { Attr, BelongsTo, Bool, Cast, Num, Str } from 'pinia-orm/decorators'

export default class Member extends Model {
    static entity = 'members'

    @Str( '', { notNullable: true } ) declare id: string
    @Str( '', { notNullable: true } ) declare firstName: string
    @Str( '', { notNullable: true } ) declare lastName: string
    @Cast( () => DateCast ) @Attr( null ) declare birthDate: string | null
    @Str( null ) declare regId: string | null
    @Str( null ) declare sex: string | null
    @Str( null ) declare phone: string | null
    @Str( null ) declare email: string | null
    @Num( null ) declare clubId: number | null
    @BelongsTo( () => Club, 'clubId' ) declare club: Club
    @Bool( false, { notNullable: true } ) declare isLicensed: boolean

    get fullName () {
        return this.firstName + " " + this.lastName
    }

    get reverseFullName () {
        return this.lastName + " " + this.firstName
    }

    get reverseShortName () {
        return this.lastName + " " + this.firstName.toShortPersonalName()
    }

    get reverseFullNameWithBirthYear () {
        return this.reverseFullName + " (" + this.birthYear + ")"
    }

    get reverseFullNameWithBirthYearWithRegId () {
        return this.regId + " " + this.reverseFullNameWithBirthYear
    }

    get reverseShortNameWithRegId () {
        return this.regId + " " + this.reverseShortName
    }

    get birthYear () {
        if ( this.birthDate === null || this.birthDate === undefined )
            return null

        const birthDate = new Date( this.birthDate )

        return birthDate.getFullYear()
    }

    static piniaOptions = {
        persist: true
    }
}