import Member from "@/orm/models/Member"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { AxiosRepository } from "@pinia-orm/axios"
import { useRepo } from "pinia-orm"

export default class MembersRepo extends AxiosRepository<Member> {
    use = Member

    hasMembers () {
        return this.all().length > 0
    }

    orderedList () {
        return this.all()
            .filter( member => member.isLicensed )
            .map( ( member ) => ( {
                value: member.id,
                label: member.reverseFullNameWithBirthYearWithRegId,
                sortLabel: member.reverseFullNameWithBirthYear,
                searchLabel: member.fullName + " " + member.reverseFullNameWithBirthYearWithRegId
            } ) )
            .sort( ( a, b ) => ( a.sortLabel.localeCompare( b.sortLabel ) ) )
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        if ( authToken.length === 0 ) {
            this.flush()

            return Promise.allSettled( [] )
        }

        this.flush()
        return this.api().get( '/catalogs/members', {
            headers: { Authorization: "Bearer " + authToken },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: String( line.id ),
                    firstName: String( line.firstName ),
                    lastName: String( line.lastName ),
                    birthDate: Object.hasOwn( line, 'birthDate' ) ? new Date( line.birthDate ) : undefined,
                    regId: String( line.id ).substring( 0, 4 ) + "-" + String( line.id ).substring( 4 ),
                    sex: String( line.sex ),
                    phone: Object.hasOwn( line, 'phone' ) ? String( line.phone ) : undefined,
                    email: Object.hasOwn( line, 'email' ) ? String( line.email ) : undefined,
                    clubId: Object.hasOwn( line, 'clubId' ) ? parseInt( line.clubId ) : undefined,
                    isLicensed: Object.hasOwn( line, 'isLicensed' ) ? Boolean( line.isLicensed ) : false
                } ) )
            }
        } )
    }
}