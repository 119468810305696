<template>
    <q-tab-panel name="EventRaceTab">
        <q-tabs v-model="selClass" align="left" dense no-caps narrow-indicator active-color="secondary" v-if="moreClasses">
            <q-tab :ripple="false" name="-all" :label="ts( 'allClasses' )" />
            <q-tab :ripple="false" :name="boatClass.boatClass.id" :label="boatClass.boatClass.name" v-for="boatClass in attendedClasses"
                   :key="boatClass.id" />
        </q-tabs>

        <div class="row q-mt-md justify-between items-start q-gutter-md">
            <div class="col-md-auto text-center q-pl-md half-width">
                <div v-if="resultsEnterModel === ResultsEnterModel.EnterNumByNum">
                    <div>
                        <div v-if="race === null || race?.unfinishedBoats( selClass ) > 0">
                            <q-select
                                ref="finishedSailNoField"
                                v-model="finishedSailNo" :label="ts( 'sailNo' )" autofocus
                                :options="unfinishedBoatsList"
                                option-value="value" option-label="label" class="qe q-mt-sm" autocomplete="true"
                                use-input emit-value map-options input-debounce="300" hide-selected fill-input

                                @filter="boatFilter" @keyup.enter.prevent="chooseBoat( null )" @keyup.tab.prevent="chooseBoat( null )" />
                            <div class="text-right">
                                <q-btn class="q-mt-md" outline color="primary" :label="ts( 'addResult' )" @click="chooseBoat( null )" />
                                <q-btn-dropdown class="q-ml-md q-mt-md" outline color="primary" :label="ts( 'addOtherResult' )">
                                    <q-list>
                                        <q-item
                                            clickable v-close-popup v-for="result in otherResultsList" :key="result.value"
                                            @click="chooseBoat( result.value )">
                                            <q-item-section avatar>
                                                {{ result.value }}
                                            </q-item-section>
                                            <q-item-section class="long-desc">
                                                {{ result.label }}
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-btn-dropdown>
                            </div>

                            <div v-if="race !== null">
                                <div class="q-mt-lg" />

                                <q-btn-dropdown class="q-mt-xl" outline color="secondary" :label="ts( 'enterMissing' )">
                                    <q-list>
                                        <q-item
                                            clickable v-close-popup v-for="result in missedResultsList" :key="result.value"
                                            @click="addMissing( result.value )">
                                            <q-item-section avatar>
                                                {{ result.value }}
                                            </q-item-section>
                                            <q-item-section class="long-desc">
                                                {{ result.label }}
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-btn-dropdown>
                            </div>
                        </div>
                        <q-card flat bordered class="q-mt-md" v-else>
                            <q-card-section class="row q-gutter-md items-center justify-between">
                                <q-icon class="col-md-auto" name="check" size="30px" color="positive" />
                                <div class="col" v-if="race?.isSkippedBoatClass( selClass )">{{ ts( 'skipRace' ) }}</div>
                                <div class="col" v-else>{{ ts( 'allResultsEntered' ) }}</div>
                            </q-card-section>
                        </q-card>
                    </div>
                    <div class="q-mt-lg"><br></div>
                    <q-markup-table flat class="q-mt-lg unfinished-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th class="text-left">{{ ts( 'boatClass' ) }}</th>
                            <th class="text-right">{{ ts( 'finished' ) }}</th>
                            <th class="text-right">{{ ts( 'unfinished' ) }}</th>
                            <th class="text-right">{{ ts( 'total' ) }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="boatClass in selectedClasses" :key="boatClass.boatClass.id"
                            :class="( race === null ? event.filteredBoats( boatClass.boatClass.id ).length > 0 : ( !race.isSkippedBoatClass( boatClass.boatClass.id ) && race.unfinishedBoats( boatClass.boatClass.id ) > 0 ) ) ? 'unfinished' : 'finished'">
                            <td class="text-center">
                                <q-icon flat name="close" color="secondary" size="22px" v-if="race?.isSkippedBoatClass( boatClass.boatClass.id )">
                                    <q-tooltip>{{ ts( 'skipRace' ) }}</q-tooltip>
                                </q-icon>
                                <q-icon flat name="cancel" color="negative" size="22px"
                                        v-else-if="race === null ? event.filteredBoats( boatClass.boatClass.id ).length > 0 : race.unfinishedBoats( boatClass.boatClass.id ) > 0">
                                    <q-tooltip>{{ ts( 'notFinishedRace' ) }}</q-tooltip>
                                </q-icon>
                                <q-icon flat name="check_circle" color="positive" size="22px" v-else>
                                    <q-tooltip>{{ ts( 'allBoatsFinishedRace' ) }}</q-tooltip>
                                </q-icon>
                            </td>
                            <td class="text-left">{{ boatClass.boatClass.name }}</td>
                            <td class="text-right">{{ race === null ? 0 : race?.finishedBoats( boatClass.boatClass.id ) }}</td>
                            <td class="text-right">
                                {{ race === null ? event.filteredBoats( boatClass.boatClass.id ).length : race.unfinishedBoats( boatClass.boatClass.id ) }}
                            </td>
                            <td class="text-right">
                                {{ race === null ? event.filteredBoats( boatClass.boatClass.id ).length : race.totalBoats( boatClass.boatClass.id ) }}
                            </td>
                            <td class="text-center">
                                <q-btn
                                    flat icon="close" color="secondary" :title="ts( 'skipRace' )"
                                    v-if="race !== null && race.finishedBoats( boatClass.boatClass.id ) === 0 && !race.isSkippedBoatClass( boatClass.boatClass.id )"
                                    @click="skipRace( boatClass.boatClass.id )"
                                />
                                <q-btn
                                    flat icon="chevron_right" color="secondary" :title="ts( 'unskipRace' )"
                                    v-else-if="race !== null && race.finishedBoats( boatClass.boatClass.id ) === 0 && race.isSkippedBoatClass( boatClass.boatClass.id )"
                                    @click="unskipRace( boatClass.boatClass.id )"
                                />
                                <q-btn
                                    flat icon="delete" color="negative" :title="ts( 'removeResultForClass' )"
                                    v-if="race !== null && race.finishedBoats( boatClass.boatClass.id ) > 0"
                                    @click="removeResultsForClass( boatClass.boatClass.id )"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </q-markup-table>
                </div>
                <div class="q-mt-lg" v-else>
                    <div class="row q-gutter-md justify-center">
                        <q-btn
                            outline :class="'col-3 boat-btn' + ( boat.finished ? ' finished' : '' )" :color="boat.finished ? 'secondary' : 'primary'" no-caps
                            :disable="boat.finished"
                            @click="finishBoat( boat )"
                            v-for="boat in race === null ? event.allBoatsOrdered( selClass ) : race?.allBoatsOrdered( selClass )" :key="boat.id">
                            <div class="sail-no">{{ boat.sailNo }}</div>
                            <div class="boat-class">{{ boat.boatClass.shortcut }}</div>
                        </q-btn>
                    </div>
                </div>

                <q-btn-dropdown outline color="secondary" class="q-mt-xl"
                                :label="ts( resultsEnterModel === ResultsEnterModel.EnterNumByNum ? 'resultsEnterNumByNum' : 'resultsEnterDirectClick' )">
                    <q-list>
                        <q-item clickable v-close-popup @click="setResultsMode( ResultsEnterModel.EnterNumByNum )">
                            <q-item-section>{{ ts( 'resultsEnterNumByNum' ) }}</q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup @click="setResultsMode( ResultsEnterModel.EnterDirectClick )">
                            <q-item-section>{{ ts( 'resultsEnterDirectClick' ) }}</q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
            </div>
            <div class="col-md-auto text-right">
                <div v-if="noResultsInRace" class="text-center">
                    <q-btn :label="ts( 'removeRace' )" color="secondary" outline class="q-mb-xl" @click="removeRace()" />
                </div>
                <div class="q-pa-md" v-if="race !== null">
                    <q-table
                        ref="raceResultsField"
                        flat :rows="currentResults" :columns="resultsColumns" row-key="index"
                        :class="{ 'results-table': true, 'scrollable-results': raceResultStyle === RaceResultStyle.ScrollableTable }"
                        v-draggable-table="raceResultStyle === RaceResultStyle.ScrollableTable ? '' : { options: { mode: currentResults.length > 2 ? 'row' : 'none', onlyBody: true, dragHandler: 'tr.draggable', dragula: { accepts: reorderOnAccept } }, onDrop: reorderResults }"
                        :virtual-scroll="raceResultStyle === RaceResultStyle.ScrollableTable" :virtual-scroll-item-size="48"
                        :virtual-scroll-sticky-size-start="48" :virtual-scroll-sticky-size-end="0"
                        v-model:pagination="pagination" :rows-per-page-options="[0]">
                        <template v-slot:body="props">
                            <q-tr
                                :props="props"
                                :class="{ 'class-separator': props.rowIndex > 0 && currentResults[ props.rowIndex - 1 ].boat?.boatClassId !== props.row.boat?.boatClassId, 'draggable': props.row.order !== null, [ 'boatClass-' + props.row.boat?.boatClassId ]: true, 'last-entered-result': props.row.boat?.id === lastEnteredBoatId }">
                                <q-td v-for="col in props.cols" :key="col.name" :props="props">
                                    <span v-if="col.name === 'actions'">
                                        <q-btn
                                            flat icon="delete" color="negative" class="qe-ignore-focus" :title="ts( 'removeResult' )"
                                            @click="removeResult( props.row )" />
                                    </span>
                                    <span v-else-if="col.name === 'index'">
                                        {{ props.rowIndex + 1 }}.
                                    </span>
                                    <span v-else-if="col.name === 'order'" :class="{ 'cursor-pointer': raceResultStyle === RaceResultStyle.DragAndDropTable }">
                                        <q-icon name="more_horiz" color="secondary" v-if="props.row.order === null" />
                                        {{ col.value }}
                                        <q-popup-edit v-model="props.row.order" :title="ts( 'editOrder' )" auto-save v-slot="scope" buttons
                                                      @save="( val, origVal ) => updateResultOrder( props.row, val, origVal )">
                                            <q-input type="number" v-model.number="scope.value" dense autofocus />
                                        </q-popup-edit>
                                    </span>
                                    <span v-else-if="col.name === 'other'" :class="{ 'cursor-pointer': raceResultStyle === RaceResultStyle.DragAndDropTable }"
                                              :title="props.row.attrInfo">
                                        <q-icon name="more_horiz" color="secondary" v-if="props.row.other === null" />
                                        {{ col.value }}
                                        <q-popup-edit
                                            v-model="props.row.other" :title="ts( 'editResult' )" auto-save v-slot="scope" buttons
                                            @save="( val, origVal ) => updateResultOther( props.row, val, origVal )">
                                            <q-select
                                                v-model="scope.value" dense autofocus :options="otherResultsList" option-value="value" option-label="label"
                                                emit-value @keyp.enter="scope.set">
                                                <template v-slot:option="scope">
                                                    <q-item v-bind="scope.itemProps">
                                                        <q-item-section avatar>
                                                            {{ scope.opt.value }}
                                                        </q-item-section>
                                                        <q-item-section class="long-desc">
                                                            {{ scope.opt.label }}
                                                        </q-item-section>
                                                    </q-item>
                                                </template>
                                            </q-select>
                                        </q-popup-edit>
                                    </span>
                                    <span v-else>{{ col.value }}</span>
                                </q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </div>
            </div>
        </div>
    </q-tab-panel>
</template>

<script setup lang="ts">
import { ns, ts } from "@/plugins/i18n-formatted"
import nvl from "@/utils/nvl"
import Confirmation from "@dialogs/Confirmation.vue"
import ResultAttr from "@dialogs/ResultAttr.vue"
import BoatClass from "@model/BoatClass"
import { ResultsEnterModel } from "@model/Event"
import EventBoat from "@model/EventBoat"
import EventRace from "@model/EventRace"
import EventRaceSkippedClass from "@model/EventRaceSkippedClass"
import EventResult from "@model/EventResult"
import { Defaults, Options, RaceResultStyle } from "@model/Setting"
import EventsRepo from "@repo/EventsRepo"
import SettingsRepo from "@repo/SettingsRepo"
import { useRepo } from "pinia-orm"
import { QSelect, useQuasar } from "quasar"
import { computed, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { missedResults, otherResultFormula, otherResults } from "@/orm/catalogs/OtherResults"

const quasar = useQuasar()
const route = useRoute()
const router = useRouter()

const event = computed( () => ( useRepo( EventsRepo ).getEvent( String( route.params.eventId ), true ) ) )

useRepo( EventsRepo ).selectEvent( event.value?.id )

const raceNo = ref( route.params.raceId )

const race = computed( () => raceNo.value === undefined ? null : useRepo( EventRace ).where( 'eventId', event.value?.id ).where( 'race', parseInt( raceNo.value ) ).withAllRecursive().first() )

watch(
    () => route.params.raceId, refresh, { immediate: true }
)

const moreClasses = computed( () => ( attendedClasses.value.length > 1 ) )
const selClass = ref( event.value?.orderedClasses.length > 1 ? '-all' : event.value?.orderedClasses[ 0 ].boatClassId )

const allClasses = computed( () => ( selClass.value === '-all' ) )
const attendedClasses = computed( () => ( event.value?.orderedClasses ) )
const selectedClasses = computed( () => ( allClasses.value ? attendedClasses.value : attendedClasses.value.filter( boatClass => boatClass.boatClass.id === selClass.value ) ) )
const unfinishedBoats = computed( () => ( event.value?.unfinishedBoats( race.value, allClasses.value ? null : selClass.value ) ) )
const currentResults = computed( () => ( race.value?.orderedResults( selClass.value ) ) )
const missedResultsList = computed( () => ( missedResults() ) )
const otherResultsList = computed( () => ( otherResults() ) )
const noResultsInRace = computed( () => !race.value?.anyResults )
const raceResultStyle = computed( () => ( useRepo( SettingsRepo ).getOption( Options.PreferredRaceResultStyle, Defaults.PreferredRaceResultStyle ) ) )

const unfinishedBoatsList = ref( unfinishedBoats.value )
const finishedSailNoField = ref()
const finishedSailNo = ref( null )
const pagination = ref()
const lastEnteredBoatId = ref( null )
const raceResultsField = ref()

const resultsEnterModel = ref( nvl( event.value.currentResultsEnterMode, ResultsEnterModel.EnterNumByNum ) )

const resultsColumns = ref( [
    {
        name: 'index',
        label: ts( 'orderShortcut' ),
        align: 'right',
        field: () => ''
    },
    {
        name: 'boatClass',
        label: ts( 'boatClass' ),
        align: 'left',
        field: row => row.boat?.boatClass.name
    },
    {
        name: 'boat',
        label: ts( 'boat' ),
        align: 'left',
        field: row => row.boat?.sailNo
    },
    {
        name: 'order',
        label: ts( 'order' ),
        align: 'right',
        field: row => row.order
    },
    {
        name: 'other',
        label: ts( 'score' ),
        align: 'right',
        field: row => nvl( row.other, '' )
    },
    {
        name: 'points',
        label: ts( 'points' ),
        align: 'right',
        field: row => ns( row.points, 'points' )
    },
    {
        name: 'actions',
        label: '',
        align: 'center',
        field: () => ''
    }
] )

async function refresh ( raceId ) {
    raceNo.value = raceId
}

function reorderOnAccept ( el, target, source, sibling ) {
    const sourceClass = el?.querySelector( '.draggable' )?.className.split( ' ' ).find( ( cl ) => cl.substring( 0, 10 ) === 'boatClass-' )
    const targetClass = sibling?.querySelector( '.draggable' )?.className.split( ' ' ).find( ( cl ) => cl.substring( 0, 10 ) === 'boatClass-' )

    return sibling?.querySelector( '.draggable' ) && sourceClass === targetClass
}

function boatFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            unfinishedBoatsList.value = unfinishedBoats.value
        } )
        return
    }

    update(
        () => {
            const needle = val.toSearchable()
            unfinishedBoatsList.value = unfinishedBoats.value.filter( member => member.label.toSearchable().indexOf( needle ) > -1 )
        },
        ( ref: QSelect ) => {
            if ( val !== "" && ref.options.length > 0 ) {
                ref.setOptionIndex( -1 )
                ref.moveOptionSelection( 1, true )
            }
        }
    )
}

function prepareRace () {
    let raceId = null

    if ( race.value === null ) {
        const savedRace = useRepo( EventRace ).save( {
            eventId: event.value.id,
            race: event.value.races.length === 0 ? 1 : Math.max( ...event.value.races.map( race => race.race ) ) + 1
        } )

        event.value?.setModified()

        router.push( { name: 'EventRaceTab', params: { raceId: savedRace.race } } )

        raceId = savedRace.id
    } else
        raceId = race.value.id

    return raceId
}

function chooseBoat ( otherCode ) {
    if ( finishedSailNo.value === null ) {
        quasar.notify( {
            icon: 'edit',
            message: ts( 'boatMissing' ),
            timeout: 2000
        } )

        return
    }

    const raceId = prepareRace()
    const boat = useRepo( EventBoat ).find( finishedSailNo.value )

    if ( boat === null )
        return

    const formula = otherCode === null ? 'order' : otherResultFormula( otherCode )

    if ( formula === null )
        return

    const needOrder = formula.indexOf( 'order' ) >= 0
    const needAttr = formula.indexOf( 'attr' ) >= 0

    if ( needAttr ) {
        const matches = formula.match( /attr\[\s*(percent|points)\s*(?:\|\s*(\d+))?\s*\]/ )

        if ( matches ) {
            const attrType = matches[ 1 ]
            const attrDefault = ( matches.length > 1 && matches[ 2 ] !== undefined ) ? parseInt( matches[ 2 ] ) : 0

            quasar.dialog( {
                    component: ResultAttr,
                    componentProps: {
                        title: ts( attrType === 'percent' ? 'getPercent' : 'getPoints' ),
                        label: ts( attrType === 'percent' ? 'percent' : 'points' ),
                        value: attrDefault,
                        type: attrType
                    }
                } )
                .onOk( ( payload ) => {
                    if ( payload.attr === null || payload.attr === undefined || isNaN( payload.attr ) )
                        return

                    const results = useRepo( EventResult ).where( 'eventRaceId', raceId ).whereHas( 'boat', ( query ) => {
                        query.where( 'boatClassId', boat.boatClassId )
                    } ).whereNotNull( 'order' ).get()

                    useRepo( EventResult ).save( {
                        eventRaceId: raceId,
                        eventBoatId: finishedSailNo.value,
                        order: needOrder ? ( results.length === 0 ? 1 : Math.max( ...results.map( result => result.order ) ) + 1 ) : null,
                        other: otherCode,
                        attr: attrType === 'percent' ? payload.attr / 100.0 : payload.attr
                    } )

                    event.value?.setModified()

                    lastEnteredBoatId.value = finishedSailNo.value

                    notifyAboutLastBoat( finishedSailNo.value )

                    finishedSailNo.value = null
                    finishedSailNoField.value.focus()
                } )

            return
        }
    }

    const results = useRepo( EventResult ).where( 'eventRaceId', raceId ).whereHas( 'boat', ( query ) => {
        query.where( 'boatClassId', boat.boatClassId )
    } ).whereNotNull( 'order' ).get()

    useRepo( EventResult ).save( {
        eventRaceId: raceId,
        eventBoatId: finishedSailNo.value,
        order: needOrder ? ( results.length === 0 ? 1 : Math.max( ...results.map( result => result.order ) ) + 1 ) : null,
        other: otherCode
    } )

    event.value?.setModified()

    showLastBoat( finishedSailNo.value )

    notifyAboutLastBoat( finishedSailNo.value )

    finishedSailNo.value = null
    finishedSailNoField.value.focus()
}

function showLastBoat ( boatId ) {
    lastEnteredBoatId.value = boatId

    if ( currentResults.value !== undefined && raceResultStyle.value === RaceResultStyle.ScrollableTable && raceResultsField.value !== undefined ) {
        const index = currentResults.value.findIndex( r => r.boat?.id === boatId )

        // ISSUE: https://github.com/quasarframework/quasar/discussions/11861 -> diky debounce pro observer na pocitani vysky se musi pockat lehce dele nez 100 :)
        setTimeout( function () {
            raceResultsField.value.scrollTo( index, 'center-force' )
        }, 300 )
    }
}

function addMissing ( otherCode ) {
    if ( race.value === null )
        return

    const raceId = race.value.id

    const finishedBoatds = race.value.results.map( result => result.eventBoatId )

    const unfinishedBoats = event.value?.filteredBoats( ( selClass.value === '-all' ) ? null : selClass.value ).filter( boat => finishedBoatds.indexOf( boat.id ) == -1 )

    unfinishedBoats.forEach( boat => {
        useRepo( EventResult ).save( {
            eventRaceId: raceId,
            eventBoatId: boat.id,
            other: otherCode
        } )
    } )

    event.value?.setModified()

    lastEnteredBoatId.value = null
}

function removeResult ( result ) {
    quasar.dialog( {
            component: Confirmation,
            componentProps: {
                icon: 'warning',
                question: ts( 'removeResultQuestion', {
                    sailNo: result.boat?.sailNo,
                    boatClass: result.boat?.boatClass.name,
                    result: result.result
                } )
            }
        } )
        .onOk( () => {
            const removedOrder = result.order
            const boatClass = result.boat?.boatClass.id
            const raceId = result.eventRaceId

            useRepo( EventResult ).destroy( result.id )

            lastEnteredBoatId.value = null

            if ( removedOrder === null )
                return

            const affectedBoats = useRepo( EventResult ).where( 'eventRaceId', raceId ).whereHas( 'boat', ( query ) => {
                query.where( 'boatClassId', boatClass )
            } ).whereNotNull( 'order' ).where( result => result.order > removedOrder ).get()

            useRepo( EventResult ).save( affectedBoats.map( boat => ( { id: boat.id, order: boat.order - 1 } ) ) )

            event.value?.setModified()
        } )
}

function updateResultOrder ( result, val ) {
    const boatClass = result.boat.boatClass.id
    const raceId = result.eventRaceId
    const removedOrder = result.order

    const results = useRepo( EventResult ).where( 'eventRaceId', raceId ).whereHas( 'boat', ( query ) => {
        query.where( 'boatClassId', result.boat.boatClassId )
    } ).whereNotNull( 'order' ).get()

    const maxOrder = ( results.length > 0 ? Math.max( ...results.map( result => result.order ) ) : 0 ) + ( removedOrder === null ? 1 : 0 )

    if ( val < 1 || val > maxOrder || val === removedOrder )
        return

    lastEnteredBoatId.value = null

    if ( removedOrder === null && val === maxOrder ) {
        useRepo( EventResult ).save( { id: result.id, order: val, other: null } )

        event.value?.setModified()

        return
    }

    const affectedBoats = useRepo( EventResult ).where( 'eventRaceId', raceId ).whereHas( 'boat', ( query ) => {
        query.where( 'boatClassId', boatClass )
    } ).whereNotNull( 'order' ).where( res => removedOrder < val ? res.order <= val && res.order > removedOrder : res.order >= val && res.order < removedOrder ).get()

    useRepo( EventResult ).save( affectedBoats.map( boat => ( { id: boat.id, order: boat.order + ( removedOrder < val ? -1 : 1 ) } ) ) )

    useRepo( EventResult ).save( { id: result.id, order: val, other: null } )

    event.value?.setModified()
}

function updateResultOther ( result, val ) {
    if ( result.other === val )
        return

    const formula = otherResultFormula( val )

    if ( formula === null )
        return

    if ( formula.indexOf( 'attr' ) >= 0 ) {
        const matches = formula.match( /attr\[\s*(percent|points)\s*(?:\|\s*(\d+))?\s*\]/ )

        if ( matches ) {
            const attrType = matches[ 1 ]
            const attrDefault = ( matches.length > 1 && matches[ 2 ] !== undefined ) ? parseInt( matches[ 2 ] ) : 0

            quasar.dialog( {
                    component: ResultAttr,
                    componentProps: {
                        title: ts( attrType === 'percent' ? 'getPercent' : 'getPoints' ),
                        label: ts( attrType === 'percent' ? 'percent' : 'points' ),
                        value: attrDefault,
                        type: attrType
                    }
                } )
                .onOk( ( payload ) => {
                    if ( payload.attr === null || payload.attr === undefined || isNaN( payload.attr ) )
                        return

                    updateResultOtherSecondStage( result, val, attrType === 'percent' ? payload.attr / 100.0 : payload.attr )
                } )

            return
        }
    }

    updateResultOtherSecondStage( result, val )
}

function updateResultOtherSecondStage ( result, val, attr = null ) {
    const boatClass = result.boat.boatClass.id
    const raceId = result.eventRaceId
    const removedOrder = result.order

    const formula = otherResultFormula( val )

    if ( formula === null )
        return

    const needOrder = formula.indexOf( 'order' ) >= 0

    if ( needOrder ) {
        if ( result.order === null ) {
            const results = useRepo( EventResult ).where( 'eventRaceId', raceId ).whereHas( 'boat', ( query ) => {
                query.where( 'boatClassId', result.boat.boatClassId )
            } ).whereNotNull( 'order' ).get()

            useRepo( EventResult ).save( { id: result.id, order: results.length === 0 ? 1 : Math.max( ...results.map( result => result.order ) ) + 1 } )
        }

        useRepo( EventResult ).save( { id: result.id, other: val, attr: attr } )

        event.value?.setModified()

        lastEnteredBoatId.value = null

        return
    }

    if ( removedOrder !== null ) {
        const affectedBoats = useRepo( EventResult ).where( 'eventRaceId', raceId ).whereHas( 'boat', ( query ) => {
            query.where( 'boatClassId', boatClass )
        } ).whereNotNull( 'order' ).where( res => res.order > removedOrder ).get()

        useRepo( EventResult ).save( affectedBoats.map( boat => ( { id: boat.id, order: boat.order - 1 } ) ) )
    }

    useRepo( EventResult ).save( { id: result.id, order: null, other: val, attr: attr } )

    event.value?.setModified()

    lastEnteredBoatId.value = null
}

function removeResultsForClass ( boatClass ) {
    quasar.dialog( {
            component: Confirmation,
            componentProps: {
                icon: 'warning',
                question: ts( 'removeResultForClassQuestion', {
                    boatClass: useRepo( BoatClass ).find( boatClass )?.name
                } )
            }
        } )
        .onOk( () => {
            const allBoats = useRepo( EventResult ).where( 'eventRaceId', race.value.id ).whereHas( 'boat', ( query ) => {
                query.where( 'boatClassId', boatClass )
            } ).get().map( result => result.id )

            useRepo( EventResult ).destroy( allBoats )

            event.value?.setModified()

            lastEnteredBoatId.value = null
        } )
}

function reorderResults ( from, to ) {
    const offset = ( pagination.value.page - 1 ) * pagination.value.rowsPerPage

    const firstResult = currentResults.value[ offset + from - 1 ]
    const secondResult = currentResults.value[ offset + to - 1 ]

    if ( firstResult.order === null || secondResult.order === null )
        return

    if ( firstResult.boat.boatClassId !== secondResult.boat.boatClassId )
        return

    const firstOrder = firstResult.order
    const secondOrder = secondResult.order

    const direction = firstOrder < secondOrder ? 1 : -1

    lastEnteredBoatId.value = null

    const allAffected = useRepo( EventResult ).where( 'eventRaceId', firstResult.eventRaceId )
        .where( 'order', ( value ) => {
            return direction === 1 ? ( value >= firstOrder && value <= secondOrder ) : ( value <= firstOrder && value >= secondOrder )
        } )
        .orderBy( 'order', direction === 1 ? 'asc' : 'desc' ).get()

    if ( allAffected.length <= 1 )
        return

    const firstOne = allAffected.shift()

    let prevOrder = firstOne.order
    let results = []

    allAffected.forEach( function ( item ) {
        results.push( { id: item.id, order: prevOrder } )

        prevOrder = item.order
    } )

    results.push( { id: firstOne.id, order: prevOrder } )

    useRepo( EventResult ).save( results )

    event.value?.setModified()
}

function removeRace () {
    useRepo( EventRace ).save( event.value.races.filter( r => r.race > race.value.race ).map( r => ( { id: r.id, race: r.race - 1 } ) ) )
    useRepo( EventRace ).destroy( race.value.id )

    event.value?.setModified()

    lastEnteredBoatId.value = null
}

function skipRace ( boatClassId ) {
    useRepo( EventRaceSkippedClass ).save( { eventRaceId: race.value.id, boatClassId: boatClassId } )

    event.value?.setModified()
}

function unskipRace ( boatClassId ) {
    const skippedClass = useRepo( EventRaceSkippedClass ).where( { eventRaceId: race.value.id, boatClassId: boatClassId } ).first()

    if ( skippedClass !== null ) {
        useRepo( EventRaceSkippedClass ).destroy( skippedClass.id )

        event.value?.setModified()
    }
}

function setResultsMode ( mode ) {
    resultsEnterModel.value = mode

    useRepo( EventsRepo ).save( { id: event.value.id, currentResultsEnterMode: mode } )

    lastEnteredBoatId.value = null
}

function finishBoat ( selBoat ) {
    const raceId = prepareRace()
    const boat = useRepo( EventBoat ).find( selBoat.id )

    if ( boat === null )
        return

    const results = useRepo( EventResult ).where( 'eventRaceId', raceId ).whereHas( 'boat', ( query ) => {
        query.where( 'boatClassId', boat.boatClassId )
    } ).whereNotNull( 'order' ).get()

    useRepo( EventResult ).save( {
        eventRaceId: raceId,
        eventBoatId: selBoat.id,
        order: ( results.length === 0 ? 1 : Math.max( ...results.map( result => result.order ) ) + 1 ),
        other: null
    } )

    event.value?.setModified()

    showLastBoat( selBoat.id )

    notifyAboutLastBoat( selBoat.id )
}

function notifyAboutLastBoat ( selBoat ) {
    const boat = useRepo( EventBoat ).find( selBoat )

    if ( event.value.unfinishedBoats( race.value, boat.boatClassId ).length === 0 ) {
        const boatClass = useRepo( BoatClass ).find( boat.boatClassId )

        quasar.notify( {
            icon: 'sports_score',
            message: ts( 'lastBoatFor', { race: race.value.race, boatClass: boatClass.name } ),
            timeout: 3000
        } )
    }
}
</script>

<style scoped lang="sass">
.class-separator td
  border-top: solid 1px

.unfinished
  color: $negative
  font-weight: bold

.unfinished-table
  font-size: 90%

.results-table
  font-size: 90%

.half-width
  width: 45% !important

.boat-btn .boat-class, .boat-btn .sail-no
  display: block
  width: 100%

.boat-btn .sail-no
  margin-top: 2px

.boat-btn .boat-class
  font-size: 80%
  line-height: 1em
  margin-bottom: 4px

.boat-btn.finished
  opacity: .3 !important

.thead-sticky tr > *
  position: sticky
  opacity: 1
  z-index: 1
  background: white

.thead-sticky tr:last-child > *
  top: 0

.scrollable-results
  overflow-x: hidden
  max-height: 60vh
  min-height: 500px

.last-entered-result
  background-color: lightcyan

.long-desc
  font-size: 80%
</style>
