<template>
	<common-page
		:label="ts( 'openFromFile' )" :ok-btn="stageBtn" :cancel-btn="preview ? ts( 'pickAnotherFile' ) : ''"
		:use-second-button="preview"
		@ok="proceed" @cancel="pickAnother">

		<div v-if="preview">
			<q-markup-table flat>
				<tbody>
				<tr>
					<td class="text-left text-weight-bold">Verze programu</td>
					<td>{{ fileVersion }}</td>
				</tr>
				<tr>
					<td class="text-left text-weight-bold">Čas uložení souboru</td>
					<td>{{ fileTimestamp }}</td>
				</tr>
				<tr>
					<td class="text-left text-weight-bold">Data z ČSJ stažena</td>
					<td>{{ ts( 'whoAndWhen', { who: csjScorer, when: csjTimestamp } ) }}</td>
				</tr>
				<tr>
					<td class="text-left text-weight-bold">Závod</td>
					<td>{{ eventDesc }}</td>
				</tr>
				<tr>
					<td class="text-left text-weight-bold vertical-top">Lodní třídy</td>
					<td>
						<q-markup-table flat>
							<tbody>
							<tr v-for="( eventClass ) in eventClasses">
								<td>{{ eventClass.classDesc }}</td>
								<td class="text-right">{{ tcs( 'boats', eventClass.boats ) }}</td>
								<td class="text-right">{{ tcs( 'races', eventClass.races ) }}</td>
							</tr>
							</tbody>
						</q-markup-table>
					</td>
				</tr>
				</tbody>
			</q-markup-table>

			<div class="text-negative q-mt-lg q-mb-lg" v-if="duplicateEvent">
				<q-icon name="error" size="2em" color="negative"/>
				<span class="q-ml-sm text-weight-bold">{{ ts( 'duplicateEvent' ) }}</span>
			</div>
		</div>
		<q-file
			v-else
			v-model="uploadedFile" autofocus flat :label="ts( 'pickFile' )"
			accept="application/json" clearable counter :counter-label="formatCounter">
			<template v-slot:prepend>
				<q-icon name="drive_folder_upload"/>
			</template>
		</q-file>
	</common-page>
</template>

<script setup lang="ts">
import CommonPage from '@/components/parts/commonPage.vue'
import { createEventFromFile } from '@/network/importer'
import { DateFormat, ds, tcs, ts } from '@/plugins/i18n-formatted'
import EventsRepo from '@repo/EventsRepo'
import { useRepo } from 'pinia-orm'
import { useQuasar } from 'quasar'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const quasar = useQuasar()
const router = useRouter()

const uploadedFile = ref()

const preview = ref( false )
const duplicateEvent = ref( false )
const fileVersion = ref( '' )
const fileTimestamp = ref( '' )
const csjTimestamp = ref( '' )
const csjScorer = ref( '' )
const eventDesc = ref( '' )
const eventClasses = ref( [] )

const stageBtn = computed( () => ts( preview.value ? 'importEvent' : 'importFile' ) )

const importedContent = ref( {} )

function formatCounter ( { totalSize } ) {
	return String( totalSize ).replace( /^(\d+(?:\.\d+)?)(.*)$/, '$1 $2' )
}

function pickAnother () {
	preview.value = false
	uploadedFile.value = null
	duplicateEvent.value = false
}

function proceed () {
	if ( preview.value )
		doImport()
	else
		doPreview()
}

function doImport () {
	preview.value = false
	uploadedFile.value = null
	duplicateEvent.value = false

	const eventId = createEventFromFile( importedContent.value )

	importedContent.value = {}

	if ( eventId !== undefined )
		router.push( { name: 'EventManager', params: { eventId: eventId } } )
}

function doPreview () {
	if ( uploadedFile.value === undefined ) {
		quasar.notify( {
			icon: 'error',
			color: 'negative',
			message: ts( 'pleaseChooseFile' ),
			timeout: 5000,
		} )

		return
	}

	const file = uploadedFile.value
	const reader = new FileReader()

	reader.onload = () => {
		importedContent.value = {}

		try {
			const rawContent = String( reader.result )
			importedContent.value = JSON.parse( rawContent )
		}
		catch ( e ) {
			quasar.notify( {
				icon: 'error',
				color: 'negative',
				message: ts( 'invalidFileFormat', { fileName: uploadedFile.value.fileName } ),
				timeout: 5000,
			} )

			return
		}

		if ( typeof importedContent.value !== 'object' || !Object.hasOwn( importedContent.value, 'software' ) || importedContent.value.software !== 'Score System' || !Object.hasOwn( importedContent.value, 'version' ) ) {
			quasar.notify( {
				icon: 'error',
				color: 'negative',
				message: ts( 'notMySaveFile', { fileName: uploadedFile.value.fileName } ),
				timeout: 5000,
			} )

			return
		}

		preview.value = true

		const eventFrom = importedContent.value.event.from.toDate()
		const eventTo = importedContent.value.event.to === null ? null : importedContent.value.event.to.toDate()

		fileVersion.value = importedContent.version
		fileTimestamp.value = ds( importedContent.value.generated.toDate(), DateFormat.DateTimeCommon )
		csjTimestamp.value = ds( importedContent.value.sailingDataTimestamp.toDate(), DateFormat.DateTimeCommon )
		csjScorer.value = importedContent.value.authorizedScorer
		eventDesc.value = importedContent.value.event.ctl + ' ' + importedContent.value.event.title
			+ ' (' + ds( eventFrom, ( eventTo != null && !eventFrom.isSameAs( eventTo ) ) ? DateFormat.EventFrom : DateFormat.EventTo )
			+ ( ( eventTo == null || eventFrom.isSameAs( eventTo ) ) ? '' : ' – ' + ds( eventTo, DateFormat.EventTo ) ) + ')'

		eventClasses.value = []

		const boats = importedContent.value.event.boats.reduce( ( acc, cur ) => acc.set( cur.id, cur.boatClassId ), new Map<string, string>( [] ) )

		importedContent.value.event.classes.forEach( function ( cl ) {
			eventClasses.value.push( {
				classDesc: cl.shortcut,
				weight: cl.weight,
				shortcut: cl.shortcut,
				boats: importedContent.value.event.boats.filter( b => b.boatClassId === cl.boatClassId ).length,
				races: importedContent.value.event.races.filter( r => r.results.filter( res => boats.get( res.eventBoatId ) === cl.boatClassId ).length > 0 ).length,
			} )
		} )

		eventClasses.value = eventClasses.value.sort( ( a, b ) => a.weight === b.weight ? a.shortcut.localeCompare( b.shortcut ) : b.weight - a.weight )

		duplicateEvent.value = useRepo( EventsRepo ).eventByCtlId( importedContent.value.event.ctl ) != null
	}

	reader.readAsText( file )
}
</script>
